import PropTypes from 'prop-types';
import Link from 'next/link';
import ReactGA from 'react-ga';
import { useRouter } from 'next/router';

import Typography from 'components/Typography';
import classes from 'styles/components/Nav/NavLink.module.scss';

const NavLink = ({ children, mobile, onClick, ...props }) => {
  const { href, external, text } = props;
  const { pathname } = useRouter();

  return external ? (
    <button
      type="button"
      onClick={() => {
        ReactGA.event({ category: text, action: `Visit external link` });
        window.open(href);
      }}
      className={mobile ? classes.mobile : classes.root}
    >
      <Typography variant="calltoaction2" upcase className={classes.blueLink}>
        {children}
      </Typography>
    </button>
  ) : (
    <Link {...props}>
      <a aria-label={href}>
        <Typography
          variant="calltoaction2"
          upcase
          className={mobile ? classes.mobile : classes.root}
          onClick={onClick}
          bold={href === pathname}
        >
          {children}
        </Typography>
      </a>
    </Link>
  );
};

NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  mobile: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  external: PropTypes.bool,
};

NavLink.defaultProps = {
  onClick: () => {},
  external: false,
};

export default NavLink;
