import PropTypes from 'prop-types';
import classNames from 'classnames';

import { arrToEnum } from 'utils';

import classes from 'styles/components/Typography.module.scss';

export const VARIANT_CHOICES = [
  'h1',
  'h2',
  'h3',
  'h4',
  'subheader1',
  'subheader2',
  'body1',
  'body2',
  'body3',
  'quote',
  'calltoaction1',
  'calltoaction2',
];

export const VARIANTS = arrToEnum(VARIANT_CHOICES);

const Typography = ({
  variant,
  bold,
  italic,
  unsetColor,
  centered,
  upcase,
  component: Component,
  className: externClassName,
  ...props
}) => {
  const boldSet = bold !== null;
  const className = classNames(
    classes[variant],
    {
      [classes.bold]: boldSet && bold,
      [classes.italic]: italic,
      [classes.regular]: boldSet && !bold,
      [classes.unset]: unsetColor,
      [classes.centered]: centered,
      [classes.upcase]: upcase,
    },
    externClassName,
  );

  return <Component className={className} {...props} />;
};

Typography.propTypes = {
  variant: PropTypes.oneOf(VARIANT_CHOICES),
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  component: PropTypes.elementType,
  className: PropTypes.string,
  unsetColor: PropTypes.bool,
  centered: PropTypes.bool,
  upcase: PropTypes.bool,
};

Typography.defaultProps = {
  variant: VARIANTS.BODY1,
  bold: false,
  italic: false,
  component: 'p',
  className: '',
  unsetColor: false,
  centered: false,
  upcase: false,
};

export default Typography;
