export const EMPLOYERS_LINK =
  'https://www.metlife.com/business-and-brokers/employee-benefits/legal-services/';

export const LOGIN = 'https://login.legalplans.com/login';

export const REGISTER = 'https://login.legalplans.com/register';

export const CALCULATOR = 'https://www.legalplans.com/calculator';

export const AMAZON_ESTATE_REGISTRATION =
  'https://members.legalplans.com/?digitalEstatePlanning=amazon';

export const CAREERS =
  'https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=269ead05-4042-4cc5-b325-75a73a6e0439&ccId=19000101_000001&lang=en_US';

export const FEDERAL_BENEFITS =
  'https://metlifefederalbenefits.com?cid=buecw&utm_id=buecw&utm_source=metlife-legal&utm_medium=display&utm_campaign=g_fedlegal_3q22_MetLife&utm_content=banner_Redirect&promoid=buecw';

export const DEP_LITE_REGISTRATION =
  'https://login.legalplans.com/register?service=digitalEstatePlanning&next=https%3A%2F%2Fwills.legalplans.com%2Fregister%3Fservice%3DdigitalEstatePlanning';
