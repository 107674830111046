import { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from 'components/Typography';
import { useRouter } from 'next/router';
import Link from 'next/link';

import Spacer from 'components/Spacer';
import Button from 'components/Button';

import classes from 'styles/components/Nav/Nav.module.scss';
import UserIcon from 'public/user-icon.svg';
import Logo from 'public/images/metlife-logo.svg';
import MenuIcon from 'icons/menu.svg';

import { LOGIN } from 'utils/constants/urls';
import NavLink from './NavLink';
import Divider from './Divider';

const joinNetwork = () => {
  window.location = '/AttorneyNetwork_Application_2024.pdf';
};

const Nav = ({ links, depLoginUrl }) => {
  const { pathname } = useRouter();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const onAttorney = pathname === '/attorney';

  // This is another instance of that "sub-component" pattern that I dislike
  // But I'll let it fly for now
  const login = (
    <>
      <Typography
        variant="calltoaction2"
        upcase
        className={classes.loginLink}
        onClick={
          onAttorney
            ? joinNetwork
            : () => {
                window.location = depLoginUrl || LOGIN;
              }
        }
      >
        <UserIcon />
        <Spacer inline h={6} />
        {onAttorney ? 'Join Network' : 'Log in'}
      </Typography>
    </>
  );

  return (
    <>
      <nav className={classes.root}>
        <Button
          aria-label="menu"
          noMinWidth
          onClick={() => setShowMobileMenu(!showMobileMenu)}
          className={classes.menuIcon}
        >
          <MenuIcon />
        </Button>

        <Link href="/">
          <a className={classes.link} aria-label="logo">
            <Logo alt="MetLife logo" className={classes.img} />
          </a>
        </Link>

        <div className={classes.showMobileMenu ? classes.mobileMenu : classes.right}>
          <div className={classes.menuLinks}>
            {links.map(({ text, href, external }) => (
              <NavLink
                key={href}
                href={href}
                text={text}
                external={external}
                mobile={showMobileMenu}
                onClick={() => {
                  setShowMobileMenu(false);
                }}
              >
                {text}
              </NavLink>
            ))}
            {showMobileMenu && <div className={classes.mobileLogin}>{login}</div>}
          </div>

          <div className={classes.login}>
            <Divider />

            {login}
          </div>
        </div>
      </nav>
    </>
  );
};

Nav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      href: PropTypes.string,
    }),
  ).isRequired,
  depLoginUrl: PropTypes.string,
};

Nav.defaultProps = {
  depLoginUrl: '',
};

export default Nav;
