import PropTypes from 'prop-types';
import classNames from 'classnames';

import { arrToEnum } from 'utils';

import classes from 'styles/components/Button.module.scss';

export const COLOR_CHOICES = ['white', 'blue', 'green', 'transparent'];
export const COLORS = arrToEnum(COLOR_CHOICES);

const Button = ({
  onClick,
  className: externClassName,
  fullWidth,
  noMinWidth,
  color,
  filled,
  children,
  ...props
}) => {
  const className = classNames(
    classes.root,
    {
      [classes.fullWidth]: fullWidth,
      // This prop was named to be more consistent with our other apps
      // because I was getting mixed up otherwise
      [classes.withMinWidth]: !noMinWidth,
      [classes.blue]: color === COLORS.BLUE,
      [classes.white]: color === COLORS.WHITE,
      [classes.green]: color === COLORS.GREEN,
      [classes.filled]: filled,
    },
    externClassName,
  );

  return (
    <button type="button" className={className} onClick={onClick} {...props}>
      {children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  noMinWidth: PropTypes.bool,
  color: PropTypes.oneOf(COLOR_CHOICES),
  filled: PropTypes.bool,
  children: PropTypes.node,
};

Button.defaultProps = {
  onClick: () => null,
  className: '',
  fullWidth: false,
  noMinWidth: false,
  color: COLORS.TRANSPARENT,
  filled: false,
  children: null,
};

export default Button;
