import PropTypes from 'prop-types';

const Spacer = ({ h: horizontal, v: vertical, inline, ...props }) => (
  <span
    {...props}
    style={{
      display: inline ? 'inline-block' : 'block',
      height: vertical,
      width: horizontal,
    }}
  />
);

Spacer.propTypes = {
  h: PropTypes.number,
  v: PropTypes.number,
  inline: PropTypes.bool,
};

Spacer.defaultProps = {
  h: 0,
  v: 0,
  inline: false,
};

export default Spacer;
