import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from 'styles/components/Content.module.scss';

const Content = ({ children, className: externClassName, ...props }) => {
  const className = classNames(classes.root, externClassName);

  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};

Content.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Content.defaultProps = {
  className: '',
};

export default Content;
